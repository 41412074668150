<template>
  <v-container class="content">
    <v-row no-gutters>
      <v-col align="center">
        <h1 class="mb-8">Stores</h1>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col align="right">
        <v-btn color="primary" :to="{ name: 'adminStoreCreate' }" dark>
          Create
          <v-icon right> mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card elevation="10">
          <v-row>
            <v-col cols="4">
              <v-text-field v-model="search" label="Search" class="mx-8"></v-text-field>
            </v-col>
            <v-col>
              <v-chip-group multiple v-model="selectedTypes">
                <v-spacer></v-spacer>
                <v-chip v-for="t in types" :key="t.name" filter :value="t.name">
                  {{ t.displayName }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
          <v-data-table
            :elevation="15"
            :headers="headers"
            :items="data"
            :server-items-length="totalElements"
            :options.sync="pageable"
            @update:options="fetchData"
            :footer-props="footerProps"
            class="elevation-1"
          >
            <template v-slot:item.createdDate="{ item }">
              <div>
                {{ new Date(item.createdDate).toLocaleString() }}
              </div>
            </template>
            <template v-slot:item.type="{ item }">
              <div>
                {{ item.type.name }}
              </div>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-chip
                color="blue accent-2"
                dark
                :to="{ name: 'adminStoreView', params: { id: item.id } }"
              >
                View
              </v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import qs from "qs";

export default {
  components: {},
  name: "AdminStoresView",
  metaInfo: {
    title: "Stores",
  },
  data() {
    return {
      data: [],
      totalElements: 0,
      headers: [
        {
          text: "Id",
          align: "start",
          value: "id",
        },
        { text: "Create Date", value: "createdDate" },
        { text: "Name", value: "name" },
        { text: "Type", value: "type" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      pageable: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
      },
      footerProps: {
        "items-per-page-options": [5, 10, 15, 25, 50, 100],
      },
      types: [],
      selectedTypes: [],
      search: '',
    };
  },
  created() {
    this.fetchTypes()
  },
  methods: {
    fetchDataDebounced() {
      // cancel pending call
      clearTimeout(this._timerId)

      // delay new call 500ms
      this._timerId = setTimeout(() => {
        this.fetchData()
      }, 500)
    },
    fetchData() {
      let queryParams = {
        page: this.pageable.page - 1,
        size: this.pageable.itemsPerPage,
        type: this.selectedTypes,
        search: this.search,
      };
      if (this.pageable.sortBy.length) {
        let direction = this.pageable.sortDesc[0] ? ",desc" : ",asc";
        queryParams.sort = this.pageable.sortBy[0] + direction;
      }
      axios
        .get("/api/admin/store", {
          params: queryParams,
          paramsSerializer: (params) => {
            return qs.stringify(params, {arrayFormat: "repeat"});
          },
        })
        .then((response) => {
          this.data = response.data.content;
          this.totalElements = response.data.totalElements;
        });
    },
    fetchTypes() {
      axios
        .get("/api/admin/store/types")
        .then((response) => {
          this.types = response.data;
        });
    },
  },
  watch: {
    selectedTypes() {
      this.fetchData()
    },
    search() {
      this.fetchDataDebounced()
    }
  }
};
</script>

<style scoped>
</style>
